import React, { useMemo } from "react";
import { Box, Flex, Img } from "@chakra-ui/react";
import "./style.css";
import imgArrow from "../../images/icons/arrow-bottom.svg";

const uniqueId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

function ArrowDropdown({ placeholder, value, onChange, options }) {
  const inputId = useMemo(() => uniqueId(), []);
  const keys = useMemo(() => Object.keys(options), [options]);

  return (
    <Box position="relative" className="ArrowDropdown">
      <label htmlFor={inputId}>
        <Flex alignItems="center">
          <Box>{options[value] || placeholder}</Box>
          <Img draggable={false} src={imgArrow} />
        </Flex>
      </label>

      <select id={inputId} value={value} onChange={onChange}>
        {placeholder && (
          <option value="" label={placeholder}>
            {placeholder}
          </option>
        )}
        {keys.map((key, i) => (
          <option
            key={`select-option-${key}-${i}`}
            label={options[key]}
            value={key}
          >
            {options[key]}
          </option>
        ))}
      </select>
    </Box>
  );
}

export default ArrowDropdown;

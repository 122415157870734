import React, { useMemo, useState, useCallback } from "react";
import { Link } from "gatsby";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import NewsCard from "../../../components/NewsCard";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import ArrowButton from "../../../components/ArrowButton";
import ArrowDropdown from "../../../components/ArrowDropdown";
import animation from "./_animation";

import newsLoader from "../../../content";

function Articles() {
  const { language, t } = useTranslation();
  const news = useMemo(() => newsLoader(language), [language]);

  const [sortBy, setSortBy] = useState("");
  const onSortByChange = useCallback((e) => setSortBy(e.target.value), []);

  return (
    <Box paddingTop="viewNewsArticlesT">
      <PageContainer>
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", xl: "50%" }}
            textAlign={{ base: "center", xl: "left" }}
          >
            <ViewportAnimation {...animation.title}>
              <ContentTitle
                titleBold={t("page@news@article@title-bold")}
                titleLight={t("page@news@article@title-light")}
                order={"hy-AM" === language ? "break" : "inline"}
                spaces="0"
              />
            </ViewportAnimation>
          </Box>

          <Box
            width={{ base: "100%", xl: "50%" }}
            textAlign={{ base: "center", xl: "right" }}
            marginTop={{ base: "2xl", xl: "0" }}
          >
            <ViewportAnimation {...animation.dropdown}>
              <ArrowDropdown
                placeholder={t("page@news@article@sort@title")}
                value={sortBy}
                onChange={onSortByChange}
                options={{
                  titleAZ: t("page@news@article@sort@title-az"),
                  titleZA: t("page@news@article@sort@title-za"),
                  date09: t("page@news@article@sort@date-09"),
                  date90: t("page@news@article@sort@date-90"),
                }}
              />
            </ViewportAnimation>
          </Box>
        </Flex>

        <Box marginTop="viewNewsArticlesContentT">
          <Box>
            <Grid
              templateColumns={{
                base: "",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              rowGap={10}
              columnGap={7}
            >
              {news.map((row, i) => (
                <GridItem key={`NewsCard-${i}-${row.id}`}>
                  <Link to={`/news/${row.id}`}>
                    <ViewportAnimation {...animation.cards}>
                      <NewsCard
                        image={row.articleImage}
                        title={row.articleTitle}
                        text={row.articleDescription}
                        date={row.articleDate}
                      />
                    </ViewportAnimation>
                  </Link>
                </GridItem>
              ))}
            </Grid>
          </Box>

          <Box
            marginTop="viewNewsArticlesMoreT"
            marginBottom="viewNewsArticlesMoreB"
          >
            <Flex justifyContent="center">
              <ViewportAnimation {...animation.button} repeat={true}>
                <ArrowButton
                  text={t("page@news@article@show-more")}
                  side="both"
                />
              </ViewportAnimation>
            </Flex>
          </Box>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default Articles;

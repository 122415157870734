import React from "react";
import { Box, Flex, Img } from "@chakra-ui/react";
import imgArrow from "../../images/icons/arrow-bottom.svg";

// side: both, left, right
function ArrowButton({ text, onClick, side }) {
  return (
    <Box onClick={onClick} cursor="pointer">
      <Flex>
        {("both" === side || "left" === side) && (
          <Img draggable={false} src={imgArrow} marginRight="2" />
        )}

        <Box fontSize="sm" fontWeight="bold">
          {text}
        </Box>

        {("both" === side || "right" === side) && (
          <Img draggable={false} src={imgArrow} marginLeft="2" />
        )}
      </Flex>
    </Box>
  );
}

ArrowButton.defaultProps = {
  side: "right",
};

export default ArrowButton;

import newsEnUs from "./en-us";
import newsHyAm from "./hy-am";
import newsRuRu from "./ru-ru";

const news = {
  "en-US": newsEnUs,
  "hy-AM": newsHyAm,
  "ru-RU": newsRuRu,
};

export default (locale) => {
  return news[locale] || [];
};

const titleAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-3.5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.85,
    delay: 0.72,
  },
};

const dropdownAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "3.5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.65,
    delay: 0.52,
  },
};

const cardsAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "5rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.25,
  },
};

const buttonAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "2.3rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.72,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: titleAnimation,
  dropdown: dropdownAnimation,
  cards: cardsAnimation,
  button: buttonAnimation,
};

import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";

import Hero from "../views/News/Hero";
import Articles from "../views/News/Articles";
// import Info from "../views/News/Info";

function News() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <Articles />
        {/* <Info /> */}
      </AnimatedContent>
    </Box>
  );
}

export default News;
